<template>
  <div class="charts-dom">
    <div>
      <div class="top-search">
        <div class="fix"></div>
        <el-form :model="dataForm" label-position="right" label-width="80px">
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="图表名称：">
                <el-input
                  v-model="dataForm.chartName"
                  placeholder="图表名称"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="所属类型：">
                <el-select
                  v-model="dataForm.business"
                  placeholder="请选择"
                  clearable
                >
                  <el-option
                    v-for="item in typeList"
                    :label="item.business"
                    :value="item.business"
                    :key="item.business"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="创建人：">
                <el-input
                  v-model="dataForm.createBy"
                  placeholder="创建人"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="6" class="toolsBar">
              <div>
                <el-button type="primary" size="small" @click="handleSearch"
                  >查询</el-button
                >
                <el-button size="small" @click="resetForm">清空</el-button>
              </div>

              <el-button
                type="primary"
                size="small"
                icon="el-icon-plus"
                @click="handleAppend"
                >新增</el-button
              >
            </el-col>
          </el-row>
        </el-form>
      </div>

      <!-- 图表 -->
      <div class="charts-box">
        <el-row :gutter="20">
          <el-col :span="8" v-for="item in tableData" :key="item.id">
            <div class="item">
              <div class="item-icon">
                <img :src="getIcon(item.defaultShape)" alt="" />
              </div>
              <div class="item-right">
                <div class="item-top">
                  <h3>{{ item.chartName }}</h3>
                  <span>{{ item.createBy }}</span>
                </div>
                <div>{{ item.business }}</div>
                <div class="charts-desc">{{ item.description }}</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
const icon_line = require("@/assets/images/resourse/line.png");
const icon_bar = require("@/assets/images/resourse/bar.png");
const icon_pie = require("@/assets/images/resourse/pie.png");
const icon_table = require("@/assets/images/resourse/table.png");
import { getChartsList, getBusinessList } from "@/api/lib/baseCharts.js";
export default {
  data() {
    return {
      icon_line,
      icon_bar,
      icon_pie,
      icon_table,
      dataForm: {},
      typeList: [],
      tableData: [],
    };
  },
  created() {
    getBusinessList().then((res) => {
      const list = res.data || [];
      this.typeList = [...list];
    });
    this.handleSearch();
  },
  computed: {
    getIcon() {
      return (icon) => {
        return this["icon_" + icon];
      };
    },
  },
  methods: {
    //查询
    handleSearch() {
      getChartsList(this.dataForm).then((res) => {
        this.tableData = res.data || [];
      });
    },
    //重置查询条件
    resetForm() {
      this.dataForm = {};
      this.handleSearch();
    },
    //新增
    handleAppend() {
      this.$emit("openModal");
    },
  },
};
</script>



<style lang="scss" scoped>
.charts-dom {
  height: calc(100vh - 60px);
  width: 100%;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow-y: auto;
  background: #fff;
  padding: 16px 20px;
}

body,
html {
  margin: 0;
  box-sizing: border-box;
}
body ::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background-color: rgba(144, 147, 153, 0.5);
}
.toolsBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
}
.top-search {
  position: sticky;
  top: 0;
  z-index: 9;
  width: calc(100% - 32px);
  background: #f7f8f9;
  padding: 16px;
  padding-top: 0;
}
.fix {
  background: #fff;
  height: 16px;
  transform: scale(1.5) translateY(-10px);
}

/deep/ .el-form-item {
  margin-bottom: 0;
}
.item {
  display: flex;
  margin-top: 12px;
  padding: 12px;
  border-radius: 4px;
  background: #f7f8f9;
  .item-icon {
    width: 90px;
    height: 90px;
    background: #fff;
    border-radius: 4px;
    margin-right: 12px;
    flex-shrink: 0;
    img {
      width: 100%;
    }
  }
  h3 {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 500;
    color: #333333;
  }
  .item-top {
    display: flex;
    justify-content: space-between;
  }
  .item-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #999;
    width: 100%;
    span {
      font-size: 14px;
      color: #333;
      font-weight: 400;
    }
    .charts-desc {
      text-overflow: ellipsis;
      white-space: nowrap;
      width: calc(100% - 10px);
      overflow: hidden;
      margin-top: 14px;
    }
  }
}
</style> 