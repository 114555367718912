var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "charts-top" }, [
      _c("div", [_vm._t("topL")], 2),
      _c("div", [_vm._t("topR")], 2)
    ]),
    _c(
      "div",
      { staticClass: "box" },
      [
        _c(
          _vm.curentView,
          _vm._g(
            _vm._b(
              { ref: "viewDom", tag: "component" },
              "component",
              _vm.$attrs,
              false
            ),
            _vm.$listeners
          )
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }