import service from './requestTemp'
//接口前缀
const BASE_PREFIX = '';


/*------------------图表库列表--------------------*/

//图表库列表
export function getChartsList(data) {
    return service({
        url: `${BASE_PREFIX}/Ark/Base/ListChart`,
        method: 'get',
        data
    })
}

//查询基础业务类型 下拉
export function getBusinessList(data) {
    return service({
        url: `${BASE_PREFIX}/Ark/Base/Properties/ListBusiness`,
        method: 'get',
        data
    })
}

//查询主题  下拉
export function getThemeList(data) {
    return service({
        url: `${BASE_PREFIX}/Ark/Base/Properties/DescribeTheme`,
        method: 'get',
        data
    })
}

//查询指标 下拉
export function getDimensionList(data) {
    return service({
        url: `${BASE_PREFIX}/Ark/Base/Properties/DescribeDimension`,
        method: 'get',
        data
    })
}

//创建图表
export function createCharts(data) {
    return service({
        url: `${BASE_PREFIX}/Ark/Base/CreateChart`,
        method: 'post',
        data
    })
}

//获取图表数据
export function queryData(data) {
    return service({
        url: `${BASE_PREFIX}/Ark/Data/Report`,
        method: 'post',
        data
    })
}

// export {
//     getBusinessList,
//     getThemeList,
//     getDimensionList
// }