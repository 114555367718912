import moment from "moment";

const pickerOptions = {
    disabledDate(time) {
        return time.getTime() > Date.now();
    },
    shortcuts: [{
            text: "今日",
            onClick(picker) {
                const start = moment(new Date()).format("YYYY-MM-DD");
                const tag = {
                    name: 'today',
                    txt: '今日'
                }

                picker.$emit("pick", [start, start, tag]);
            },
        },
        {
            text: "昨日",
            onClick(picker) {
                const date = new Date();
                date.setTime(date.getTime() - 3600 * 1000 * 24);
                const start = moment(date).format("YYYY-MM-DD");
                const tag = {
                    name: 'yesterday',
                    txt: '昨日'
                }

                picker.$emit("pick", [start, start, tag]);
            },
        },
        {
            text: "本周",
            onClick(picker) {
                const date = new Date();
                const end = moment(date).format("YYYY-MM-DD");
                const weekday = date.getDay() || 7;

                date.setDate(date.getDate() - weekday + 1);
                const tag = {
                    name: 'thisWeek',
                    txt: '本周'
                }
                const start = moment(date).format("YYYY-MM-DD");

                picker.$emit("pick", [start, end, tag]);
            },
        },
        {
            text: "上周",
            onClick(picker) {
                const date = new Date();
                const date2 = new Date();
                //上周1
                date.setDate(date.getDate() - 7 - date.getDay() + 1);
                //上周日
                date2.setDate(date2.getDate() - 1);

                const tag = {
                    name: 'lastWeek',
                    txt: '上周'
                }
                const start = moment(date).format("YYYY-MM-DD");
                const end = moment(date2).format("YYYY-MM-DD");

                picker.$emit("pick", [start, end, tag]);
            },
        },
        {
            text: "本月",
            onClick(picker) {
                const date = new Date()
                const end = moment(date).format("YYYY-MM-DD");
                date.setDate(1);
                const start = moment(date).format("YYYY-MM-DD");
                const tag = {
                    name: 'thisMonth',
                    txt: '本月'
                }
                picker.$emit("pick", [start, end, tag]);
            },
        },
        {
            text: "上月",
            onClick(picker) {
                const start = moment().month(moment().month() - 1).startOf('month').format("YYYY-MM-DD");
                const end = moment().month(moment().month() - 1).endOf('month').format("YYYY-MM-DD");
                const tag = {
                    name: 'lastMonth',
                    txt: '上月'
                }
                picker.$emit("pick", [start, end, tag]);

            },
        },
        {
            text: "近7日",
            onClick(picker) {
                const date = new Date()
                const end = moment(date).format("YYYY-MM-DD");
                date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                const start = moment(date).format("YYYY-MM-DD");

                const tag = {
                    name: 'day7',
                    txt: '近7日'
                }
                picker.$emit("pick", [start, end, tag]);
            },
        },
        {
            text: "过去7日",
            onClick(picker) {
                const date = new Date();
                const date2 = new Date();
                date.setTime(date.getTime() - 3600 * 1000 * 24 * 8);
                date2.setTime(date2.getTime() - 3600 * 1000 * 24);

                const start = moment(date).format("YYYY-MM-DD");
                const end = moment(date2).format("YYYY-MM-DD");

                const tag = {
                    name: 'day8',
                    txt: '过去7日'
                }
                picker.$emit("pick", [start, end, tag]);
            },
        },
        {
            text: "近30日",
            onClick(picker) {
                const date = new Date();
                const end = moment(date).format("YYYY-MM-DD");
                date.setTime(date.getTime() - 3600 * 1000 * 24 * 30);
                const start = moment(date).format("YYYY-MM-DD");
                const tag = {
                    name: 'day30',
                    txt: '近30日'
                }
                picker.$emit("pick", [start, end, tag]);
            },
        },
        {
            text: "过去30日",
            onClick(picker) {
                const date = new Date();
                const date2 = new Date();
                date.setTime(date.getTime() - 3600 * 1000 * 24 * 31);
                date2.setTime(date2.getTime() - 3600 * 1000 * 24);

                const start = moment(date).format("YYYY-MM-DD");
                const end = moment(date2).format("YYYY-MM-DD");

                const tag = {
                    name: 'day31',
                    txt: '过去30日'
                }
                picker.$emit("pick", [start, end, tag]);
            },
        },
        {
            text: "近90日",
            onClick(picker) {
                const date = new Date();
                const end = moment(date).format("YYYY-MM-DD");
                date.setTime(date.getTime() - 3600 * 1000 * 24 * 90);
                const start = moment(date).format("YYYY-MM-DD");

                const tag = {
                    name: 'day90',
                    txt: '近90日'
                }
                picker.$emit("pick", [start, end, tag]);
            },
        },
        {
            text: "过去90日",
            onClick(picker) {
                const date = new Date();
                const date2 = new Date();
                date.setTime(date.getTime() - 3600 * 1000 * 24 * 91);
                date2.setTime(date2.getTime() - 3600 * 1000 * 24);
                const start = moment(date).format("YYYY-MM-DD");
                const end = moment(date2).format("YYYY-MM-DD");
                const tag = {
                    name: 'day91',
                    txt: '过去90日'
                }
                picker.$emit("pick", [start, end, tag]);
            },
        },
    ],
}

export default pickerOptions