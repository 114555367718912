<template>
  <div class="edit-modal">
    <div class="modal-top">
      <div>
        <div>
          <div class="msg-txt" v-if="!isEdit" @click="handleInputTxt">
            <span>未命名的仪表盘</span>
            <i class="el-icon-edit"></i>
          </div>
          <div v-show="isEdit" class="msg-txt">
            <input type="text" v-model="dataForm.chartName" ref="chartsName" />
          </div>
        </div>
        <div class="remark">
          <input
            type="text"
            v-model="dataForm.description"
            placeholder="点击添加说明"
          />
        </div>
      </div>
      <div>
        <el-button size="small" @click="cancelAppend">取消</el-button>
        <el-button size="small" type="primary" @click="handleSubmit"
          >保存</el-button
        >
      </div>
    </div>

    <div class="modal-center">
      <div class="center-left">
        <h3 class="mb-32">指标查询条件</h3>
        <div class=" ">
          <div class="mb-32">
            <div class="title-txt">所属业务类型：</div>
            <el-select
              class="type-box"
              @change="getDefTheme"
              v-model="dataForm.business"
            >
              <el-option
                v-for="item in businessList"
                :key="item.business"
                :value="item.business"
                :label="item.business"
              />
            </el-select>
          </div>

          <div class="mb-32">
            <div class="title-txt">查询主题：</div>
            <div
              class="type-item ptb-16"
              v-for="(item, index) in checkThemeList"
              :key="item.uuid"
            >
              <div class="item-icon">{{ index | getAscll }}</div>
              <div>
                <el-popover placement="bottom" width="60" trigger="click">
                  <template>
                    <div class="pop-box">
                      <div
                        class="option-item"
                        v-for="temp in themeList"
                        :key="temp.themeName"
                        @click="checkTheme(temp, index)"
                      >
                        {{ temp.themeName }}
                      </div>
                    </div>
                  </template>

                  <el-tag slot="reference" size="small ">{{
                    item.themeName
                  }}</el-tag>
                </el-popover>
              </div>
              <div>的</div>
              <div>
                <el-popover placement="bottom" width="60" trigger="click">
                  <template>
                    <div class="pop-box">
                      <div
                        class="option-item"
                        v-for="temp in item.itemList"
                        :key="temp.metricField"
                        @click="checkThemeLast(temp, index)"
                      >
                        {{ temp.metricName }}
                      </div>
                    </div>
                  </template>
                  <el-tag slot="reference" size="small ">{{
                    item.lastName || "请选择"
                  }}</el-tag>
                </el-popover>
              </div>
              <!-- <div>的</div>
              <div>
                <el-popover placement="bottom" width="60" trigger="click">
                  <template>
                    <div>
                      <div
                        class="option-item"
                        v-for="temp in getTypeList(item.metricType)"
                        :key="temp.key"
                        @click="checkAggregator(temp, index)"
                      >
                        {{ temp.value }}
                      </div>
                    </div>
                  </template>
                  <el-tag slot="reference" size="small ">{{
                    item.aggregatorDesc || "请选择"
                  }}</el-tag>
                </el-popover>
              </div> -->
              <i
                class="el-icon-delete del-item"
                @click="handleDel(item.uuid)"
              ></i>
            </div>

            <div class="type-item ptb-16" v-if="checkThemeList.length == 0">
              <div class="item-icon">
                <i class="el-icon-plus"></i>
              </div>
              <div>
                <el-popover placement="bottom" trigger="click">
                  <template>
                    <div class="pop-box">
                      <div
                        class="option-item"
                        v-for="item in themeList"
                        :key="item.themeName"
                        @click="checkItem(item)"
                      >
                        {{ item.themeName }}
                      </div>
                    </div>
                  </template>

                  <el-tag slot="reference" size="small" class="el-icon-plus"
                    >增加主题</el-tag
                  >
                </el-popover>
              </div>
            </div>
          </div>

          <div class="mb-32">
            <div class="title-txt">细分维度：</div>
            <div class="ptb-16 tag-item" v-show="checkDimensionList.length > 0">
              <el-tag
                size="small"
                v-for="(item, index) in checkDimensionList"
                :key="item.segmentName"
                closable
                @close="handleCloseDimension(index)"
                >{{ item.segmentName }}</el-tag
              >
            </div>

            <div class="ptb-16" v-show="checkDimensionList.length == 0">
              <el-popover placement="bottom" trigger="click">
                <template>
                  <div class="pop-box">
                    <div
                      class="option-item"
                      v-for="item in dimensionList"
                      :key="item.segmentName"
                      @click="checkDimensionItem(item)"
                    >
                      {{ item.segmentName }}
                    </div>
                  </div>
                </template>
                <el-tag slot="reference" size="small" class="el-icon-plus"
                  >请选择</el-tag
                >
              </el-popover>
            </div>
          </div>

          <div class="mb-32">
            <div class="title-txt">数据范围：</div>
            <div class="ptb-16 tag-item">
              <el-tag
                size="small"
                v-for="(item, index) in checkDataRangeList"
                :key="item.rangeName"
                closable
                @close="handleCloseDataRange(index)"
                >{{ item.rangeName }}</el-tag
              >
            </div>
            <div class="ptb-16" v-show="checkDataRangeList.length == 0">
              <el-popover placement="bottom" trigger="click">
                <template>
                  <div class="pop-box">
                    <div
                      class="option-item"
                      v-for="item in dataRangeList"
                      @click="checkDataRange(item)"
                      :key="item.rangeName"
                    >
                      {{ item.rangeName }}
                    </div>
                  </div>
                </template>
                <el-tag slot="reference" size="small" class="el-icon-plus">
                  选择范围
                </el-tag>
              </el-popover>
            </div>
          </div>
        </div>

        <el-button
          class="create-charts-btn"
          size="small"
          type="primary"
          @click="creatCharts"
          >确定</el-button
        >
      </div>

      <div
        class="center-right"
        v-loading="loading"
        element-loading-text="拼命加载中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.4)"
      >
        <div>
          <charts-item
            :chartsType="chartsType"
            :xData="xData"
            :yData="yData"
            :legged="legged"
            :dbDate="dbDate"
            :chartsUUID="chartsUUID"
            id="baseC"
          >
            <template #topL>
              <div class="left-time">
                <div @click="showPicker('picker')" class="time-box">
                  <i class="el-icon-date"></i>
                  <label class="ml-10">{{ currentDate.txt }}</label>
                </div>
                <el-date-picker
                  popper-class="self-picker"
                  ref="picker"
                  class="picker-box"
                  v-model="startDate"
                  type="daterange"
                  align="right"
                  @change="(v) => selectDate(v, 1)"
                  value-format="yyyy-MM-dd"
                  unlink-panels
                  :picker-options="pickerOptions"
                >
                </el-date-picker>

                <div class="flex-item" v-if="false">
                  <el-button
                    size="mini"
                    type="primary"
                    class="mr-10"
                    :disabled="allownClick"
                    @click="compareDisplay()"
                    >{{ showCompare ? "取消" : "对比" }}</el-button
                  >
                  <div v-show="showCompare" class="flex-item">
                    <!-- <div>
                      <el-select
                        v-model="endDate.type"
                        @change="changEndDateType"
                        class="compare-type"
                      >
                        <el-option value="1" label="较上一段时间"></el-option>
                        <el-option value="2" label="去年同期"></el-option>
                        <el-option value="3" label="自定义"></el-option>
                      </el-select>
                    </div> -->
                    <div @click="showPicker('picker2')">
                      <el-date-picker
                        popper-class="self-picker"
                        ref="picker2"
                        class="picker-box2"
                        v-model="dbDate"
                        type="daterange"
                        align="right"
                        @change="(v) => selectDate(v, 2)"
                        value-format="yyyy-MM-dd"
                        unlink-panels
                        :picker-options="pickerOptions"
                      >
                      </el-date-picker>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <template #topR>
              <div v-show="xData.length > 0">
                <i
                  class="iconfont its_ba_chart_biaoge right-item"
                  :class="{ isAct: chartsType == 'table' }"
                  @click="changeChartsType('table')"
                ></i>
                <i
                  class="iconfont its_ba_chart_bingtu right-item"
                  :class="{
                    isAct: chartsType == 'pie',
                  }"
                  @click="changeChartsType('pie')"
                ></i>
                <i
                  class="iconfont its_ba_chart_zhuzhuangtu right-item"
                  :class="{ isAct: chartsType == 'bar' }"
                  @click="changeChartsType('bar')"
                ></i>
                <i
                  class="iconfont its_ba_chart_mianjizhexiantu right-item"
                  :class="{ isAct: chartsType == 'line' }"
                  @click="changeChartsType('line')"
                ></i>
              </div>
            </template>
          </charts-item>
        </div>
        <!-- <div v-if="xData.length == 0" class="no-data">
          <span v-if="legged.length == 0 && !loading">请选择查询条件 ~</span>
          <span v-else-if="!loading">查询失败 ~</span>
        </div> -->
      </div>
    </div>
    <div v-show="false" id="bgx"></div>
  </div>
</template>

<script>
import ChartsItem from "./ChartsItem";
import pickerOptions from "./PickerOptions";
import moment from "moment";
import {
  getBusinessList,
  getThemeList,
  getDimensionList,
  queryData,
  createCharts,
} from "@/api/lib/baseCharts.js";

const defTypeList = [
  { key: "SUM", value: "总和" },
  { key: "UNIQCOUNT", value: "去重数" },
  { key: "MAX", value: "最大值" },
  { key: "MIN", value: "最小值" },
  { key: "AVG", value: "均值" },
];
export default {
  name: "AppendCharts",
  components: {
    ChartsItem,
  },
  data() {
    return {
      isEdit: false,
      showCompare: false,
      dataForm: {},
      businessList: [], //业务类型下拉
      startDate: [],
      dbDate: [],
      checkThemeList: [], //已选择的查询主题
      themeList: [], //可选的主题
      checkDimensionList: [], //已选细分维度
      dimensionList: [], //可选细分维度
      // aggregatorList: [], //聚合维度
      checkDataRangeList: [], //已选数据范围
      dataRangeList: [], //可选数据范围
      pickerOptions,
      currentDate: {}, //当前起始
      endDate: {}, //对比时间
      optionPie: {},
      chartsType: null,

      xData: [], //X轴数据
      yData: [], //Y轴数据
      legged: [], //图例（指标）
      loading: false,
      chartsUUID: null,
    };
  },
  created() {
    this.getBusinessType();
    const d = moment(new Date()).format("YYYY-MM-DD");
    const startD = moment(new Date()).subtract(30, "days").format("YYYY-MM-DD");
    this.startDate = [startD, d];
    this.currentDate = { name: "day30", txt: "近30日", type: "2" };
    this.endDate = { name: "today", txt: "今日", type: "2" };
  },
  filters: {
    getAscll(i) {
      return String.fromCharCode(65 + i);
    },
  },
  computed: {
    getTypeList() {
      return (type) => {
        let list = [];
        switch (type) {
          case "NUMBER":
            list = defTypeList.filter((item) => item.key != "UNIQCOUNT");
            break;
          case "STRING":
          case "BOOL":
            list = defTypeList.filter((item) => item.key == "UNIQCOUNT");
            break;
        }
        return list;
      };
    },
    allownClick() {
      let len1 = this.checkThemeList.length; //主题
      let len2 = this.checkDimensionList.length; //维度
      let len3 = this.checkDataRangeList.length; //范围
      if (len1 === 1 && len2 == 0 && len3 === 0) {
        return false;
      }
      return true;
    },
  },
  methods: {
    //获取所属业务类型
    getBusinessType() {
      getBusinessList().then((res) => {
        if (res.code === 0) {
          const list = res.data || [];
          this.businessList = [...list];
          if (list.length) {
            let firstItem = list[0];
            this.dataForm = Object.assign({}, this.dataForm, {
              business: firstItem.business,
              tableName: firstItem.wideTableName,
            });
            this.getDefTheme(firstItem.business, false);
          }
          return;
        }
        this.$message.error(res.msg);
      });
    },
    //业务类型发生变更自动查询主题
    getDefTheme(business, flag = true) {
      //TODO
      this.checkThemeList = [];
      this.themeList = []; //可选的主题
      this.checkDimensionList = []; //已选细分维度
      this.dimensionList = []; //可选细分维度
      this.checkDataRangeList = []; //已选数据范围
      this.dataRangeList = []; //可选数据范围

      let len = this.businessList.length;
      if (flag && len > 0) {
        let current = this.businessList.find(
          (item) => item.business === business
        );
        this.dataForm = Object.assign({}, this.dataForm, {
          tableName: current.wideTableName,
        });
      }
      getThemeList({ business }).then((res) => {
        this.themeList = res.data || [];
      });
    },

    //新增主题
    checkItem(item) {
      document.querySelector("#bgx").click();
      let { themeName } = item;
      let obj = {
        uuid: this.createUUID(),
        themeName,
      };
      this.checkThemeList.push(obj);
      this.getDefTarget({
        business: this.dataForm.business,
        theme: themeName,
        uuid: obj.uuid,
      });
      this.getDefDimension();
    },
    //删除主题
    handleDel(uuid) {
      this.checkThemeList = this.checkThemeList.filter((t) => t.uuid !== uuid);
      this.checkDataRangeList = [];
      this.checkDimensionItem = [];
      this.getDefDimension();
    },
    createUUID() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
        let r = (Math.random() * 8) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
    //查询主题后面的指标
    getDefTarget({ business, theme, uuid }) {
      getDimensionList({ business, theme }).then((res) => {
        let index = this.checkThemeList.findIndex((item) => item.uuid === uuid);
        let current = this.checkThemeList[index];
        current = Object.assign({}, current, { itemList: res.data });
        this.checkThemeList.splice(index, 1, current);
      });
    },
    //修改已选主题前标签
    checkTheme(item, index) {
      document.querySelector("#bgx").click();
      let { themeName, uuid } = item;
      let obj = {
        uuid,
        themeName,
      };
      this.checkThemeList.splice(index, 1, obj);
      this.getDefTarget({
        business: this.dataForm.business,
        theme: themeName,
        uuid,
      });
      this.getDefDimension();
    },
    //选择/修改 主题 后标签
    checkThemeLast(item, index) {
      document.querySelector("#bgx").click();
      let {
        metricField: lastCode,
        metricName: lastName,
        metricType,
        dataRangeList,
        segmentList,
      } = item;
      let current = this.checkThemeList[index];
      if (current.lastCode === lastCode) {
        return;
      }
      let temp = {};
      if (metricType == "NUMBER") {
        temp = {
          aggregator: "SUM",
          aggregatorDesc: "总和",
        };
      } else {
        temp = {
          aggregator: "UNIQCOUNT",
          aggregatorDesc: "去重数",
        };
      }
      let obj = Object.assign({}, current, {
        lastCode,
        lastName,
        metricType,
        dataRangeList,
        segmentList,
        ...temp,
      });
      this.checkThemeList.splice(index, 1, obj);
      //选择主题后标签 之后  细分维度超集更新  数据范围超集更新
      this.getDefDimension();
    },
    //选择，修改 主题后的聚合维度
    checkAggregator({ key, value }, index) {
      document.querySelector("#bgx").click();
      let current = this.checkThemeList[index];
      if (current.aggregator === key) {
        return;
      }
      let obj = Object.assign({}, current, {
        aggregator: key,
        aggregatorDesc: value,
      });
      this.checkThemeList.splice(index, 1, obj);
    },
    //获取细分维度可选列表
    getDefDimension() {
      let arr1 = [],
        arr2 = [],
        list = [];

      this.checkThemeList.forEach(({ dataRangeList, segmentList }) => {
        dataRangeList && (arr1 = [...arr1, ...dataRangeList]);
        segmentList && (arr2 = [...arr2, ...segmentList]);
      });
      list = Array.from(new Set(arr1.map(({ rangeName }) => rangeName)));
      arr1 = list.map((item) => {
        let current = arr1.find((temp) => temp.rangeName === item);
        return current;
      });
      list = Array.from(new Set(arr2.map(({ segmentName }) => segmentName)));
      arr2 = list.map((item) => {
        let current = arr2.find((temp) => temp.segmentName === item);
        return current;
      });

      this.dataRangeList = [...arr1];
      this.dimensionList = [...arr2];
    },
    //删除细分维度
    handleCloseDimension(index) {
      this.checkDimensionList.splice(index, 1);
    },
    //删除范围
    handleCloseDataRange(index) {
      this.checkDataRangeList.splice(index, 1);
    },
    //新增数据范围
    checkDataRange(item) {
      document.querySelector("#bgx").click();
      let current = this.checkDataRangeList.find(
        (temp) => temp.rangeName === item.rangeName
      );
      if (current) {
        return;
      }
      this.checkDataRangeList.push(item);
    },
    //新增细分维度
    checkDimensionItem(item) {
      document.querySelector("#bgx").click();
      let current = this.checkDimensionList.find(
        (temp) => temp.segmentName === item.segmentName
      );
      if (current) {
        return;
      }
      this.checkDimensionList.push(item);
    },
    //提交
    handleSubmit() {
      let queryCondition = this.getSearch();
      let params = { queryCondition, ...this.dataForm };
      params.wideTable = queryCondition.tableName;
      params.defaultShape = this.chartsType;

      if (!params.chartName) {
        this.$message.error("请输入图表名称！");
        return;
      }
      if (this.xData.length == 0) {
        this.$message.error("请先查询数据生成图表！");
        return;
      }
      this.loading = true;
      createCharts(params)
        .then((res) => {
          if (res.code == 0) {
            this.$emit("handleOk");
            this.$message.success("操作成功！");
            return;
          }
          this.$message.error(res.msg);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //取消编辑
    cancelAppend() {
      this.$emit("handleOk");
    },
    //录入标题
    handleInputTxt() {
      this.isEdit = true;
      this.$nextTick(() => {
        this.$refs.chartsName.focus();
      });
    },
    //生成查询条件
    getSearch() {
      //主题
      let measures = this.checkThemeList.map((item) => {
        let {
          aggregator,
          lastCode: field,
          // lastName: name,
          metricType: dateType,
          themeName,
          lastName,
          aggregatorDesc,
        } = item;
        return {
          aggregator,
          field,
          name: themeName + "的" + lastName + "的" + aggregatorDesc,
          dateType,
        };
      });
      //维度
      let byFields = this.checkDimensionList.map((item) => {
        let { segmentColumn: field, segmentName: name, dataType } = item;
        return { field, name, type: "NO_SUM", dataType };
      });
      //范围
      let conditions = this.checkDataRangeList.map((item) => {
        let { rangeColumn: field, rangeName: name, dataType } = item;
        return { field, function: "EQ", params: [name], dataType };
      });
      let filter = {
        conditions,
        relation: "AND",
      };
      let { tableName } = this.dataForm;
      let params = {
        measures,
        byFields,
        filter,
        tableName,
        fromDate: this.startDate[0],
        toDate: this.startDate[1],
        dateValue:
          this.currentDate.name == "custom"
            ? [this.startDate[0], this.startDate[1]]
            : [this.currentDate.txt],

        unit: "DAY",
        displayNumber: "10",
        chartsType: "bar",
      };
      if (this.showCompare) {
        let compareObj = {
          compareFromDate: this.dbDate[0],
          compareToDate: this.dbDate[1],
          compareDateValue: [this.dbDate[0], this.dbDate[1]],
        };
        params = { ...params, ...compareObj };
      }

      return params;
    },
    //查询数据,生成图表
    creatCharts() {
      let flag = true;
      let legged = this.checkThemeList.map((item) => {
        let { themeName, lastName, aggregatorDesc } = item;
        if (!lastName || !aggregatorDesc) {
          flag = false;
        }
        return themeName + "的" + lastName + "的" + aggregatorDesc;
      });
      if (legged.length == 0) {
        this.$message.error("请选择查询主题!");
        return;
      }
      let params = this.getSearch();

      if (!flag) {
        this.$message.error("请完善主题指标!");
        return;
      }
      this.loading = true;

      queryData(params)
        .then((res) => {
          if (res.code != 0) {
            this.$message.error(res.msg);
            this.xData = [];
            this.yData = [];
            return;
          }
          let data = res.data.result || [];
          if (data.length) {
            let x = data[0].seriesList;
            let y = data.map((item) => Object.values(item.data).flat()).flat();
            this.xData = [...x];
            this.yData = [...y];
            this.legged = [...legged];
            this.changeChartsType(this.chartsType ? this.chartsType : "table");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //图表展示类型切换
    changeChartsType(type) {
      this.chartsType = type;
      this.chartsUUID = this.createUUID();
    },
    //重写时间插件样式
    showPicker(node) {
      // const offsetLeft = this.$refs[node].$el.offsetLeft;
      this.$refs[node].focus();
    },
    //展示比较
    compareDisplay() {
      let len = this.checkThemeList.length;
      if (!this.showCompare && len === 1) {
        this.showCompare = true;
        this.endDate = { name: "custom", txt: "自定义", type: "3" };
        this.dbDate = [...this.startDate];
      } else {
        this.showCompare = false;
        this.endDate = {};
        this.dbDate = [];
      }
      this.creatCharts();
    },
    //对比时间变更
    changEndDateType(t) {
      //1 上一段时间   2 去年同期  3自定义
      if (t == 1) {
      }
    },
    //时间变更
    selectDate(val, suorce) {
      if (!val) {
        this.showCompare = false;
        this.creatCharts();
        return;
      }
      if (val.length == 2) {
        this.currentDate = { name: "custom", txt: "自定义" };
        this.endDate = { name: "custom", txt: "自定义", type: "3" };
      } else {
        const tag = val.pop();
        this.currentDate = { ...tag };
        this.endDate = { ...tag, type: "2" };
        if (suorce == 1) {
          this.startDate = [...val];
        }
      }
      if (this.showCompare) {
        this.startDate = [...val];
        this.dbDate = [...val];
      }
      this.creatCharts();
    },
  },
};
</script>

<style lang='scss' scoped>
$defFontColor: #9154e7;
$defBgColor: #e9ddfa;
.edit-modal {
  box-sizing: border-box;
  height: calc(100vh - 100px);
  min-height: 600px;
  overflow-y: auto;
}
.modal-top {
  display: flex;
  justify-content: space-between;
  padding: 16px 20px;
  background: #fff;
  border-radius: 4px;
  .msg-txt {
    font-size: 24px;
    color: $defFontColor;
    font-weight: 400;
    & > i {
      margin-left: 18px;
      cursor: pointer;
    }
    input {
      color: $defFontColor;
    }
  }
  input {
    border: none;
    outline: none;
    min-width: 600px;
  }
  .remark {
    margin-top: 10px;
  }
}
.modal-center {
  margin-top: 20px;
  display: flex;
  overflow: hidden;
  & > div {
    box-sizing: border-box;
    padding: 16px 24px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 0px 4px 0px rgba(52, 52, 52, 0.1);
    min-height: calc(100vh - 220px);
  }
}
.center-left {
  /deep/.el-tag--small {
    background: $defBgColor;
    color: $defFontColor;
    cursor: pointer;
  }
  width: 330px;
  margin-right: 30px;
  position: relative;
  color: rgba(0, 0, 0, 0.85);
  .title-txt {
    color: #000;
  }
  h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
  }
  .type-box {
    width: 100%;
    margin-top: 10px;
  }
  .ptb-16 {
    padding-top: 16px;
  }
  .mb-32 {
    margin-bottom: 32px;
  }
  .type-item {
    display: flex;
    align-items: center;
    font-size: 12px;
    & > div {
      margin-right: 12px;
    }

    .item-icon {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: $defFontColor;
      margin-right: 16px;
      color: #fff;
      text-align: center;
      line-height: 22px;
      margin-right: 24px;
      flex-shrink: 0;
    }
    .del-item {
      color: #f56c6c;
      font-size: 16px;
      transition: all 0.3s;
    }
    .del-item:hover {
      cursor: pointer;
      transform: scale(1.2);
    }
  }
  .tag-item > span {
    margin-right: 12px;
  }
  .create-charts-btn {
    // position: absolute;
    // bottom: 22px;
    font-size: 14px;
    margin-top: 50px;
  }
}
.option-item {
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  transition: all 0.3s;
  padding: 0 12px;
  font-size: 12px;
}
/deep/.el-popover {
  padding: 12px 0;
}
.option-item:hover {
  background: $defBgColor;
  color: $defFontColor;
}
.center-right {
  flex-grow: 1;
  width: calc(100% - 340px);
  // /deep/ .picker-box {
  //   width: 0;
  //   height: 0;
  //   padding: 0;
  //   overflow: hidden;
  // }
  .ml-10 {
    margin-left: 10px;
  }
  .left-time {
    display: flex;
    align-items: center;
    & > div {
      margin-right: 12px;
    }
    /deep/ .picker-box {
      width: 0;
      height: 0;
      padding: 0;
      overflow: hidden;
    }
  }
  .time-box {
    color: #8c8c8c;
  }
  .compare-type {
    /deep/ input {
      border: none;
      outline: none;
      width: 140px;
    }
  }
  .flex-item,
  .charts-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .right-item {
    color: rgba(0, 0, 0, 0.45);
    margin-left: 16px;
    cursor: pointer;
  }
  .isAct {
    color: $defFontColor;
  }
  .disb {
    color: #8c8c8c;
  }
  .picker-box2 {
    width: 260px;
  }
  .charts-node-box {
    width: 100%;
    height: calc(100vh - 120px);
  }
}
.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.mr-10 {
  margin-right: 10px;
}
.pop-box {
  max-height: 300px;
  overflow: auto;
}
</style>