var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isAppend
        ? _c("charts-list", { on: { openModal: _vm.openModal } })
        : _c("append-charts", { on: { handleOk: _vm.closeModal } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }