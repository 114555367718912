<template>
  <div>
    <charts-list v-if="!isAppend" @openModal="openModal" />
    <!-- 编辑图表 -->
    <append-charts @handleOk="closeModal" v-else />
  </div>
</template>

<script>
import ChartsList from "./ChartsList";
import AppendCharts from "./AppendCharts";
const typeList = [
  { name: "全部", code: "" },
  { name: "客运班线", code: "1" },
  { name: "客运班线2", code: "2" },
  { name: "客运班线3", code: "3" },
];
export default {
  components: { AppendCharts, ChartsList },
  data() {
    return {
      dataForm: {},
      typeList,
      isAppend: false,
    };
  },
  computed: {},
  methods: {
    //新增
    openModal() {
      this.isAppend = true;
    },
    closeModal() {
      this.isAppend = false;
    },
  },
};
</script>
