<template>
  <div>
    <div class="charts-top">
      <div><slot name="topL"></slot></div>
      <div><slot name="topR"></slot></div>
    </div>

    <div class="box">
      <component
        :is="curentView"
        v-bind="$attrs"
        v-on="$listeners"
        ref="viewDom"
      ></component>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    chartsType: {
      type: String,
      default: "",
    }, 
  },
  data() {
    return {
      chartsId: null,
      curentView: null,
    };
  },

  watch: {
    chartsType() {
      this.chartsId = null;
      this.curentView = null;
      this.changeLoad();
    },
  },
  mounted() {
    // this.loadComponent("BarCharts");
  },
  methods: {
    changeLoad() {
      this.chartsId = this.id;
      const BaseType = {
        table: "TableCharts",
        pie: "PieCharts",
        bar: "BarCharts",
        line: "LineCharts",
      };
      if (BaseType[this.chartsType]) {
        this.loadComponent(BaseType[this.chartsType]);
      }
    },
    loadComponent(name = "") {
      this.curentView = () => import(`./chartsComponents/${name}.vue`);
    },
  },
};
</script>

<style lang="scss" scoped>
.charts-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.box {
  height: calc(100vh - 300px);
}
</style>